<template>
  <section class="page-container">
    <div class="flex-sb-center">
      <div
        class="header-title-back flex hand"
        @click="() => $router.back()"
      >
        <i class="el-icon-arrow-left"></i>
        <span>
          Back
        </span>
      </div>
      <div>
        <v-button
          v-if="claimId"
          class="button-delete"
          icon="trash"
          @click="handleDel">
          Delete</v-button>
        <v-button
          class="button-info"
          @click="() => $router.back()">Cancel</v-button>
        <v-button
          class="button-theme"
          @click="submitForm">Save</v-button>
      </div>
    </div>
    <div class="flex-justify header-title-back mg-t-10">
      <h2>{{claimId?'Edit':'Create'}} Claim</h2>
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
    >

      <v-title title="Progress"></v-title>

      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Status</span>
                <div class="car-type-radio">
                  <el-form-item
                    ref="status"
                    prop="status"
                    :rules="rules.select">
                    <v-radio-group
                      v-model="formData.status"
                      :border="true"
                      :list="claimStatusOptions"
                    >
                    </v-radio-group>
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Claimed Amount</span>
                <el-form-item
                  ref="claimed_amount"
                  prop="claimed_amount">
                  <v-input
                    v-model='formData.claimed_amount'
                    money
                    key-non-chinese
                    prepend="HKD"
                  />
                </el-form-item>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
      <v-title title="Claim Record"></v-title>
      <el-row
        class="row-bg"
        :gutter="30"
        justify="space-around">
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Accident Date</span>
                <el-form-item prop="accident_date">
                  <v-date-picker
                    v-model="formData.accident_date"
                  ></v-date-picker>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Brief description of the accident</span>
                <el-form-item
                  ref="accident_desc"
                  prop="accident_desc">
                  <v-input
                    v-model="formData.accident_desc"
                    type="textarea"
                    :rows="4"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Contact Person</span>
                <el-form-item
                  ref="contact_name"
                  prop="contact_name">
                  <v-input
                    v-model="formData.contact_name"
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item ">
                <span>Contact Number</span>
                <el-form-item
                  ref="contact_mobile"
                  prop="contact_mobile">
                  <v-input
                    v-model="formData.contact_mobile"
                    key-number
                    maxlength="8"
                    key-non-chinese
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
      </el-row>
      <v-title title="Related Document"></v-title>
      <div class="document">
        <section>
          <div class="flex-sb">
            <div class="form-item form-half document-file ">
              <span>Documents</span>
              <div
                v-for="(item,index) in formData.documents"
                :key="index"
                class="flex theme-second-blue">
                <file-container-card
                  :edit="true"
                  card
                  :fileData="item"
                  :otherData="{
                    index: index,
                  }"
                  @delete="handlerDelete"
                /></div>


              <el-form-item prop="covernote_document_file_id">
                <v-upload
                  ref="upload"
                  v-model="formData.document_id"
                  :multiple="true"
                  :limit="10"
                  @uploadSuccess="handlerDocumentSuccess"></v-upload>
              </el-form-item>

            </div>
          </div>
        </section>
      </div>

    </el-form>
    <div class="bottom-button">
      <v-button
        class="button-theme"
        @click="submitForm">Save</v-button>
    </div>
  </section>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import AddressForm from '@/views/components/address-form'
import {createClaim, updateClaim,delClaim} from '@api/claim'
import {mapState} from 'vuex'
export default {
  name: 'claimCreate',
  components: {
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
  },
  data(){
    return{
      formData:{
        status:'',
        claimed_amount:'',
        accident_date:'',
        accident_desc:'',
        contact_name:'',
        contact_mobile:'',
        documents:[],
        document:[],
        document_id:''
      },
    }
  },
  computed:{
    ...mapState('claim', ['claimDetail']),

    claimId(){
      let {id} = this.$route.params
      return id
    },
    order_no(){
      let {order_no} = this.$route.query||{}
      return order_no
    }
  },
  watch:{
    claimDetail:{
      immediate:true,
      handler(val){
        if(val)
        {
          Object.keys(this.formData).some(key => {
            if (key in val) {
              this.formData[key] = val[key]
              if(key=='documents')
              {
                val.documents.filter(item=>{
                  this.formData.document.push(item.file_id)
                })
              }
            }
          })
        }
      }
    }
  },
  mounted(){

  },
  methods:{
    handleDel(){
      this.network().delClaim()
    },
    // file-container 組件刪除文件
    handlerDocumentSuccess({ data, result, otherData }){
      const { file_id,path,url } = data
      this.formData.documents.push({file_id,client_name:path,url:url})
      this.formData.document.push(file_id)

    },
    handlerDelete({otherData}){
      let {index} = otherData||{}
      this.formData.document.splice(index,1)
      this.formData.documents.splice(index,1)
      this.$refs.upload.clearFiles()
    },
    submitForm(formName,is_draft) {
      // 獲取表單組件數據
      this.$refs.form.validate((valid,rules) => {
        if (valid) {
          if(this.claimId)
            this.network().updateClaim()
          else
            this.network().createClaim()
        }else {
          this.scrollToValidDom(rules)
        }
      })
    },
    network() {
      return {
        createClaim: async (params) => {
          const { data } = await createClaim({
            ...this.formData,
            ...params,
            order_no:this.order_no,
            documents:this.formData.document
          })
          this.$router.back()
        },
        delClaim: async (params) => {
          const { data } = await delClaim({
            claim_id:this.claimId,
          })
          this.$router.back()
        },
        updateClaim: async (params) => {
          const { data } = await updateClaim({
            ...this.formData,
            ...params,
            claim_id:this.claimId,
            order_no:this.order_no,
            documents:this.formData.document
          })
          this.$router.back()
        },
      }
    },
  }
}
</script>

<style scoped lang="scss">
.page-container{
  .header-title-back{
    .mg-t-10{
      margin-top: 10px;
    }
  }
  .address-content{
    max-width: 770px;
  }
  .mg-b-30{
    margin-bottom: 30px;
  }
  .bottom-button{
    max-width: 770px;
    width: 100%;
    text-align: right;
  }

  .document{
    background: #fff;
    border-radius: 8px;
    max-width: 770px;
    padding: 20px;
    margin-bottom: 30px;
    .document-file{
      .flex{
        margin: 5px 0;
        align-items: center;
        span{
          font-weight: normal!important;
        }
        i{
          margin-right: 10px;
        }
      }
    }
    .hr-inline{
      margin: 15px 0;
    }
  }
}
</style>
